'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
var prefix = 'fas';
var iconName = 'badge-dollar';
var width = 512;
var height = 512;
var aliases = [];
var unicode = 'f645';
var svgPathData = 'M256 0c36.8 0 68.8 20.7 84.9 51.1C373.8 41 411 49 437 75s34 63.3 23.9 96.1C491.3 187.2 512 219.2 512 256s-20.7 68.8-51.1 84.9C471 373.8 463 411 437 437s-63.3 34-96.1 23.9C324.8 491.3 292.8 512 256 512s-68.8-20.7-84.9-51.1C138.2 471 101 463 75 437s-34-63.3-23.9-96.1C20.7 324.8 0 292.8 0 256s20.7-68.8 51.1-84.9C41 138.2 49 101 75 75s63.3-34 96.1-23.9C187.2 20.7 219.2 0 256 0zm21 149.3c0-11.1-9-20.1-20.1-20.1s-20.1 9-20.1 20.1v8.5c-6.7 1.3-13.1 3.2-19 5.9c-16.1 7.3-29.9 20.8-33.3 40.2c-1.9 10.9-.9 21.4 3.6 30.9c4.4 9.3 11.3 15.9 18.5 20.8c12.6 8.5 29.3 13.6 42.4 17.5l0 0 2.3 .7c15.4 4.6 26.3 8.3 33.2 13.3c3 2.2 4.3 4 4.9 5.4c.6 1.3 1.2 3.9 .4 8.7c-.8 4.7-3.5 8.6-10.2 11.4c-7.3 3.1-18.7 4.5-33.2 2.3c-6.9-1.1-19-5.3-29.6-8.9l0 0c-2.4-.8-4.8-1.6-6.9-2.3c-10.5-3.5-21.8 2.2-25.3 12.7s2.2 21.8 12.7 25.3c1.4 .5 3.1 1 5 1.7l0 0 0 0c9.3 3.2 23.9 8.2 34.5 10.4v8.9c0 11.1 9 20.1 20.1 20.1s20.1-9 20.1-20.1v-8c6.5-1.1 12.6-2.8 18.4-5.3c16.9-7.2 30.3-21.1 33.8-41.4c1.9-11.2 1.1-21.8-3.1-31.4C322 267 315.2 260 308 254.8c-13.2-9.6-30.8-14.9-44.4-19l-.8-.2c-15.6-4.7-26.7-8.2-33.8-13c-3.1-2.1-4.3-3.7-4.8-4.8c-.4-.9-1.1-2.8-.3-6.9c.5-3.1 2.9-7.2 10.4-10.6c7.6-3.4 19.1-5.2 33.1-3c4.9 .8 20 3.7 24.3 4.9c10.7 2.8 21.6-3.5 24.5-14.2s-3.5-21.6-14.2-24.5c-5.3-1.4-17.9-3.9-25-5.2v-8.8z';

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [
    width,
    height,
    aliases,
    unicode,
    svgPathData
  ]};

exports.faBadgeDollar = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = aliases;
exports.unicode = unicode;
exports.svgPathData = svgPathData;
exports.aliases = aliases;